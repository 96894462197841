import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {
  public analytics_id = 'G-NB5EEKQXSL'
  platform_config = {
    title: 'Chatbot',
  }
  public service_host = 'https://raftest.chatbotbe.alteram.co.za';
  public service_port = '5000';
  public service_url  = this.service_host;
  public process_config = {
    name: "RAF-CHATBOT",
    input_always_enabled: false,
  };
  public default_km_summary = "Click here to view FAQ"
  live_chat_max_character = 200;
  public max_visible_rows = 3; //range can be from 1 to 25
  
  public bo_server_error_msg = 'Something went wrong! We are currently unable to process your request'
  public dept_contact_conf = [
    {
      "dept_keyword":"raf",
      "dept_display_name": "Road Accident Fund (RAF) Contact Centre Contact",
      "email": '',
      "tel_mobile": "087 820 1111",
    }
  ]

 public chat_header_config = {
    show_dce_branding: false,
    dce_branding_text: "Powered by Leggero DCE"
  }

  public internet_conn_conf = {
    enable: true,
    net_ping_interval: 5000,
    net_disconnection_buffer: 5000,
    back_online_display_timer: 3000
 }

  constructor() { }
}
